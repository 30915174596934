<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">人脸绑定</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt="" width="15" height="25">
      </div>
    </div>
    <div class="content">
      <div class="tips">请上传照片</div>
      <div class="load">
        <van-uploader
          v-model="fileList"
          :after-read="(file) => onLoadCard(file, 'front')"
          :max-count="1"
          :max-size="200 * 1024 * 1024"
          @oversize="onOversize"
          @delete="onRemove"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onloadImage } from '@/api/api'
import cancel from '@/assets/cancel.png'
import { Toast } from 'vant'

export default {
  name: 'Recognize',
  data() {
    return {
      cancel,
      fileList: [],
      card: ''
    }
  },
  methods: {
    toBack() {
      this.$router.go(-1)
    },
    onOversize() {
      Toast.fail('照片超出大小，请控制在50M')
    },
    async onLoadCard(file, direction) {
      console.log(file, this.fileList)
      this.card = file.content
      const res = await onloadImage({
        fileName: file.file.name,
        data: this.card.split(',')[1]
      })
      const { code, data } = res
      if (code === 0) {
        const { url } = data
        this.fileList = [{ url }]
        Toast.success('上传成功')
      }
    },
    onRemove() {
      this.fileList = []
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  & > .nav {
    width: 100%;
    position: fixed;
    top: 0;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }
  }

  & > .content {
    padding-top: 4.5rem;
    padding-left: 1.5rem;

    & > .load {
      margin-top: 1rem;
    }
  }
}

</style>
